import { Link, withIntl } from '../../i18n'
import { StaticQuery, graphql } from 'gatsby'
import { bannerIcon, fluidImage, step } from '../../fragments/fragments'

import Banner from '../../components/reusables/banner'
import Centered from '../../components/reusables/centered'
import { FormattedMessage } from 'react-intl'
import IconColumns from '../../components/reusables/iconColumns'
import ImageText from '../../components/reusables/imageText'
import IndexMenu from '../../components/reusables/indexMenu'
import Layout from '../../components/layout'
import LearningResources from '../../components/reusables/learningResources'
import React from 'react'
import Steps from '../../components/reusables/steps'

const DeveloperPortal = ({ data }) => (
  <Layout>
    <Banner data={data} shade={'light'} />
    <Centered shade={'light'} index="0" />
    <IndexMenu shade={'grey'} />
    <LearningResources data={data} shade={'light'} />
    {/* <Steps index={0} data={data} shade={'light'} /> */}
    <IconColumns index={0} data={data} shade={'grey'} />
  </Layout>
)

export default withIntl(DeveloperPortal)

export const imagesQuery = graphql`
  query learning($locale: String!) {
    allPrismicBlogPost(
      filter: { lang: { eq: $locale }, tags: { eq: "resource" } }
      sort: { fields: [data___published_date], order: DESC }
    ) {
      edges {
        node {
          lang
          uid
          alternate_languages {
            uid
            lang
            type
          }
          data {
            title {
              text
            }
            published_date
            description {
              text
            }
            author {
              text
            }
            hero {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 200) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
            content {
              html
            }
          }
        }
      }
    }
    allPrismicEvents(
      filter: { lang: { eq: $locale } }
      sort: { fields: [data___published_date], order: DESC }
    ) {
      edges {
        node {
          lang
          uid
          data {
            title {
              text
            }
            link {
              url
            }
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 200) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicVideos(
      filter: { lang: { eq: $locale } }
      sort: { fields: [data___published_date], order: DESC }
    ) {
      edges {
        node {
          lang
          uid
          data {
            title {
              text
            }
            link {
              url
            }
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 200) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
    bannerLity: file(relativePath: { eq: "hero/bannerLity@3x.png" }) {
      ...bannerIcon
    }
    step1: file(relativePath: { eq: "regular/step1@3x.png" }) {
      ...step
    }
    step2: file(relativePath: { eq: "regular/step2@3x.png" }) {
      ...step
    }
    step3: file(relativePath: { eq: "regular/step3@3x.png" }) {
      ...step
    }
    step4: file(relativePath: { eq: "regular/step4@3x.png" }) {
      ...step
    }
    github: file(relativePath: { eq: "icons/github@3x.png" }) {
      ...step
    }
    telegram: file(relativePath: { eq: "icons/telegram@3x.png" }) {
      ...step
    }
    forum: file(relativePath: { eq: "icons/forum@3x.png" }) {
      ...step
    }
    shortLastSection: file(
      relativePath: { eq: "hero/shortLastSection@2x.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
