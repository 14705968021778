import { Box, Diagram, Stack } from 'grommet'
import {
  StyledColumnContainer,
  StyledColumnsContainer,
  StyledContainer,
  StyledH3,
  StyledP,
  StyledSpan,
  StyledUnderlinedH2,
} from './styles'
import { injectIntl, intlShape } from 'react-intl'

import Button from '../reusables/button'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React from 'react'
import media from 'styled-media-query'
import shortid from 'shortid'
import styled from 'styled-components'

const StyledContentContainer = styled(Box)``

const StyledStepsContainer = styled(Box)``

const StyledStepContainer = styled(Box)`
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-rows: auto;
  grid-template-columns: 100%;
  grid-column-gap: 105px;
  grid-row-gap: 40px;
  grid-template-areas:
    'node'
    'box';
  ${media.greaterThan('medium')`
    grid-template-rows: 100%;
    grid-template-columns: auto;
    grid-template-areas:
      "node box";
  `};
`

const NodeContainer = styled(Box)`
  grid-area: node;
  min-width: 72px;
  ${media.lessThan('small')`
    margin-bottom: 10px;
  `};
`

const StyledNode = styled(Box)`
  border: 1px solid ${props => props.theme.colors.primaryDark};
  border-radius: 50%;
  line-height: 70px;
  text-align: center;
  font-size: 30px;
`

const ContentContainer = styled(Box)`
  grid-area: box;
  display: grid;
  padding: 30px 24px;
  justify-items: center;
  align-items: center;
  grid-template-rows: auto;
  grid-template-columns: 100%;
  grid-template-areas:
    'image'
    'content';
  border: 1px solid ${props => props.theme.colors.primaryDark}
    ${media.greaterThan('small')`
    grid-template-columns: 30% 70%;
    grid-template-rows: 100%;
    grid-template-areas:
      'image content';
  `};
  ${media.greaterThan('large')`
    grid-template-columns: 20% 80%;
  `};
`

const StyledStepImageContainer = styled(Box)`
  grid-area: image;
  width: 72px;
  height: auto;
  justify-self: start;
  margin-bottom: 20px;

  ${media.greaterThan('small')`
    justify-self: center;
    margin-bottom: 0px;
  `};
`

const StyledImageContainer = styled(Box)`
  height: 72px;
`

const StyledStepContentContainer = styled(Box)`
  grid-area: content;
`

const StyledStepP = styled(StyledSpan)`
  font-size: 16px;
`
const StyledStepH3 = styled(StyledH3)`
  margin: 0;
  color: #0b0c17;
  text-transform: none;
`

const Steps = ({ index, data, shade, intl }) => {
  const steps = `steps.steps${index}`
  const title = intl.formatMessage({
    id: `${steps}.title`,
  })
  const hasTitle = title !== 'null'
  const description = intl.formatMessage({
    id: `${steps}.description.description`,
  })
  const hasDescription = description !== 'null'
  const isRichText =
    intl.formatMessage({
      id: `${steps}.description.richText`,
    }) !== 'null'

  const buttons = intl.formatMessage({
    id: `${steps}.buttons.number`,
  })
  const number = intl.formatMessage({
    id: `${steps}.number`,
  })

  const backgroundImage = intl.formatMessage({
    id: `${steps}.backgroundImage`,
  })
  return (
    <StyledContainer
      componentName="Steps"
      backgroundImage={backgroundImage}
      data={data}
      shade={shade}
    >
      <StyledContentContainer align="center" justify="center" width="full">
        {hasTitle && <StyledUnderlinedH2>{title}</StyledUnderlinedH2>}
        {hasDescription && !isRichText ? (
          <StyledStepP>{description}</StyledStepP>
        ) : (
          hasDescription &&
          isRichText && (
            <StyledStepP dangerouslySetInnerHTML={{ __html: description }} />
          )
        )}
        <StyledStepsContainer>
          {Array.from(Array(Number(number)).keys()).map((step, index) => {
            const section = `${steps}.step${index}`
            const stepTitle = intl.formatMessage({
              id: `${section}.title`,
            })
            const hasStepTitle = stepTitle !== 'null'
            const stepDescription = intl.formatMessage({
              id: `${section}.description.description`,
            })
            const hasStepDescription = stepDescription !== 'null'
            const isStepRichText =
              intl.formatMessage({
                id: `${section}.description.richText`,
              }) !== 'null'

            const stepButtons = intl.formatMessage({
              id: `${section}.buttons.number`,
            })

            const stepIcon = `${section}.image`
            const imageLink = intl.formatMessage({
              id: `${stepIcon}.link`,
            })
            const hasLink = imageLink !== 'null'

            const gif = intl.formatMessage({
              id: `${stepIcon}.gif`,
            })
            const isGif = gif !== 'null'
            const iconPath = intl.formatMessage({
              id: `${stepIcon}.path`,
            })
            let imagePath = 'imagePath'
            if (!isGif) {
              imagePath = data[`${iconPath}`].childImageSharp.fixed
            } else {
              imagePath = require(`./gifs/${iconPath}.gif`)
            }
            const node = intl.formatMessage({
              id: `${section}.step`,
            })
            const hasNode = node !== 'null'

            return (
              <StyledStepContainer
                align="center"
                key={shortid.generate()}
                margin={{ bottom: 'large' }}
              >
                <NodeContainer>
                  {hasNode && <StyledNode>{node}</StyledNode>}
                </NodeContainer>
                <ContentContainer
                  pad={{ vertical: 'small', horizontal: 'large' }}
                >
                  <StyledStepImageContainer key={shortid.generate()}>
                    {hasLink ? (
                      <a href={imageLink} target="_blank">
                        {isGif ? (
                          <StyledImageContainer>
                            <img alt={iconPath} src={imagePath} />
                          </StyledImageContainer>
                        ) : (
                          <StyledImageContainer>
                            <Img
                              alt={iconPath}
                              imgStyle={{ objectFit: 'contain' }}
                              fixed={imagePath}
                            />
                          </StyledImageContainer>
                        )}
                      </a>
                    ) : isGif ? (
                      <StyledImageContainer>
                        <img alt={iconPath} src={imagePath} />
                      </StyledImageContainer>
                    ) : (
                      <StyledImageContainer>
                        <Img
                          alt={iconPath}
                          imgStyle={{ objectFit: 'contain' }}
                          fixed={imagePath}
                        />
                      </StyledImageContainer>
                    )}
                  </StyledStepImageContainer>
                  <StyledStepContentContainer>
                    {hasStepTitle && <StyledStepH3>{stepTitle}</StyledStepH3>}
                    {hasStepDescription && !isStepRichText ? (
                      <StyledStepP>{stepDescription}</StyledStepP>
                    ) : (
                      hasStepDescription &&
                      isStepRichText && (
                        <StyledStepP
                          dangerouslySetInnerHTML={{ __html: stepDescription }}
                        />
                      )
                    )}
                    {stepButtons > 0 && (
                      <Box
                        direction="row-responsive"
                        gap="medium"
                        pad={{ vertical: 'medium' }}
                      >
                        {Array.from(Array(Number(stepButtons)).keys()).map(
                          i => {
                            const stepButton = `button${i}`
                            const stepButtonLabel = intl.formatMessage({
                              id: `${section}.buttons.${stepButton}.label`,
                            })
                            const stepButtonLink = intl.formatMessage({
                              id: `${section}.buttons.${stepButton}.link`,
                            })
                            return (
                              <Button
                                key={shortid.generate()}
                                label={stepButtonLabel}
                                link={stepButtonLink}
                                light={
                                  shade === 'light' || shade === 'grey'
                                    ? true
                                    : false
                                }
                              />
                            )
                          }
                        )}
                      </Box>
                    )}
                  </StyledStepContentContainer>
                </ContentContainer>
              </StyledStepContainer>
            )
          })}
        </StyledStepsContainer>
        {buttons > 0 && (
          <Box
            direction="row-responsive"
            gap="medium"
            pad={{ vertical: 'medium' }}
          >
            {Array.from(Array(Number(buttons)).keys()).map(i => {
              const button = `button${i}`
              const buttonLabel = intl.formatMessage({
                id: `${section}.buttons.${button}.label`,
              })
              const buttonLink = intl.formatMessage({
                id: `${section}.buttons.${button}.link`,
              })
              return (
                <Button
                  key={shortid.generate()}
                  label={buttonLabel}
                  link={buttonLink}
                  light={shade === 'light' || shade === 'grey' ? true : false}
                />
              )
            })}
          </Box>
        )}
      </StyledContentContainer>
    </StyledContainer>
  )
}

Steps.propTypes = {
  data: PropTypes.object,
  shade: PropTypes.string,
  index: PropTypes.number,
  intl: intlShape.isRequired,
}

export default injectIntl(Steps)
