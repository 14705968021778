import {
  StyledColumnContainer,
  StyledColumnsContainer,
  StyledContainer,
  StyledH2,
  StyledH3,
  StyledP,
  StyledUnderlinedH2,
} from './styles'
import { injectIntl, intlShape } from 'react-intl'

import { Box } from 'grommet'
import Img from 'gatsby-image'
import Link from './link'
import PropTypes from 'prop-types'
import React from 'react'
import media from 'styled-media-query'
import shortid from 'shortid'
import styled from 'styled-components'

const StyledTitle = styled(StyledH3)`
  color: #0b0c17;
  text-transform: none;
  align-self: center;
  ${media.greaterThan('small')`
    align-self: start;
  `}
`

const StyledRowContainer = styled.div`
  width: 100%;
  text-align: center;
`
const StyledVideosContainer = styled(StyledRowContainer)`
  margin-top: 20px;
  // border-top: 1px solid ${props => props.theme.global.colors.primaryDark};
  // border-bottom: 1px solid ${props => props.theme.global.colors.primaryDark};
  padding: 20px 0;
`

const StyledTileContainer = styled(StyledColumnContainer)`
  width: auto;
  display: grid;
  margin: 5px;
  justify-items: start;
  align-items: start;
  ${media.greaterThan('small')`
    width: 400px;
  `}

  p {
    text-align: left;
  }
`

const StyledTilesContainer = styled(StyledColumnsContainer)`
  justify-content: center;
  margin: 0 -20px;
  padding: 0;
`

const StyledImageContainer = styled(Box)`
  width: 240px;
  height: auto;
  ${media.greaterThan('small')`
    height: 200px;
    width: 400px;
  `}
`

const StyledDate = styled(StyledP)`
  margin-top: 15px;
  font-size: 16px;
  opacity: 0.7;
`
const LearningResources = ({ data, shade, intl }) => {
  // console.log('data:' , data.allPrismicBlogPost.edges.length)
  const section = `learningResources`
  const title = intl.formatMessage({
    id: `${section}.title`,
  })
  const articles = `${section}.articles`
  const articlesTitle = intl.formatMessage({
    id: `${articles}.title`,
  })
  const articlesButtonLabel = intl.formatMessage({
    id: `${articles}.buttons.label`,
  })
  const articlesButtonLink = intl.formatMessage({
    id: `${articles}.buttons.link`,
  })
  const videos = `${section}.videos`
  const videosTitle = intl.formatMessage({
    id: `${videos}.title`,
  })
  const videosButtonLabel = intl.formatMessage({
    id: `${videos}.buttons.label`,
  })
  const videosButtonLink = intl.formatMessage({
    id: `${videos}.buttons.link`,
  })
  const meetups = `${section}.meetups`
  const meetupsTitle = intl.formatMessage({
    id: `${meetups}.title`,
  })
  const meetupsButtonLabel = intl.formatMessage({
    id: `${meetups}.buttons.label`,
  })
  const meetupsButtonLink = intl.formatMessage({
    id: `${meetups}.buttons.link`,
  })
  return (
    <StyledContainer componentName="LearningResources" shade={shade}>
      <StyledUnderlinedH2>{title}</StyledUnderlinedH2>
      {data.allPrismicBlogPost && (
        <StyledRowContainer>
          <StyledTitle>{articlesTitle}</StyledTitle>
          <StyledTilesContainer columns={3}>
            {data.allPrismicBlogPost.edges
              .slice(0, 3)
              .map(({ node }, index) => {
                return (
                  node.data.hero.localFile && (
                    <StyledTileContainer
                      align="center"
                      key={shortid.generate()}
                      width={'100%'}
                    >
                      <a href={`/${node.lang}/blog/${node.uid}`}>
                        <StyledImageContainer
                          justify="center"
                          round="1px"
                          background={
                            shade === 'light'
                              ? 'primaryGrey'
                              : shade === 'grey'
                              ? 'primaryWhite'
                              : 'primaryDark'
                          }
                        >
                          <Img
                            alt="hero image for post"
                            imgStyle={{ objectFit: 'contain' }}
                            fluid={
                              node.data.hero.localFile.childImageSharp.fluid
                            }
                          />
                        </StyledImageContainer>
                      </a>
                      <StyledP>{node.data.title.text}</StyledP>
                    </StyledTileContainer>
                  )
                )
              })}
          </StyledTilesContainer>
          <Link
            key={shortid.generate()}
            label={articlesButtonLabel}
            link={articlesButtonLink}
            light={false}
          />
        </StyledRowContainer>
      )}
      {data.allPrismicVideos && (
        <StyledVideosContainer>
          <StyledTitle>{videosTitle}</StyledTitle>
          <StyledTilesContainer columns={3}>
            {data.allPrismicVideos.edges.slice(0, 3).map(({ node }, index) => {
              return (
                node.data.image.localFile && (
                  <StyledTileContainer
                    align="center"
                    key={shortid.generate()}
                    width={'100%'}
                  >
                    <a href={node.data.link.url} target="_blank">
                      <StyledImageContainer
                        justify="center"
                        round="1px"
                        background={
                          shade === 'light'
                            ? 'primaryGrey'
                            : shade === 'grey'
                            ? 'primaryWhite'
                            : 'primaryDark'
                        }
                      >
                        <Img
                          alt="hero image for post"
                          imgStyle={{ objectFit: 'contain' }}
                          fluid={
                            node.data.image.localFile.childImageSharp.fluid
                          }
                        />
                      </StyledImageContainer>
                    </a>
                    <StyledP>{node.data.title.text}</StyledP>
                  </StyledTileContainer>
                )
              )
            })}
          </StyledTilesContainer>
          <Link
            key={shortid.generate()}
            label={videosButtonLabel}
            link={videosButtonLink}
            light={false}
          />
        </StyledVideosContainer>
      )}
      {data.allPrismicEvents && (
        <StyledRowContainer>
          <StyledTitle>{meetupsTitle}</StyledTitle>
          <StyledTilesContainer columns={3}>
            {data.allPrismicEvents.edges.slice(0, 3).map(({ node }, index) => {
              return (
                node.data.image.localFile && (
                  <StyledTileContainer
                    align="center"
                    key={shortid.generate()}
                    width={'100%'}
                  >
                    <a href={node.data.link.url} target="_blank">
                      <StyledImageContainer
                        justify="center"
                        round="1px"
                        background={
                          shade === 'light'
                            ? 'primaryGrey'
                            : shade === 'grey'
                            ? 'primaryWhite'
                            : 'primaryDark'
                        }
                      >
                        <Img
                          alt="hero image for post"
                          imgStyle={{ objectFit: 'contain' }}
                          fluid={
                            node.data.image.localFile.childImageSharp.fluid
                          }
                        />
                      </StyledImageContainer>
                    </a>
                    <StyledP>{node.data.title.text}</StyledP>
                  </StyledTileContainer>
                )
              )
            })}
          </StyledTilesContainer>
          <Link
            key={shortid.generate()}
            label={meetupsButtonLabel}
            link={meetupsButtonLink}
            light={false}
          />
        </StyledRowContainer>
      )}
    </StyledContainer>
  )
}

LearningResources.propTypes = {
  data: PropTypes.object,
  shade: PropTypes.string,
  index: PropTypes.number,
  intl: intlShape.isRequired,
}

export default injectIntl(LearningResources)
